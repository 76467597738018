<template>
  <v-app>
    <div class="header">
      <div class="logo">
        <img src="./assets/img/logo_b.png">
      </div>
      <div class="shop">
        {{ companyName }}
      </div>
    </div>
    <div class="nav">
      <div class="inner">
        <v-btn 
          text
          plain
          v-for="(item, index) in machineTabs" 
          :key="index"
          :class="{active:navActive === index}"
          @click="setCategory(index)"
        >
            {{item}}
        </v-btn>
      </div>
    </div>
    
    <router-view />

    <div class="footer">
      <div class="inner">
        <div class="logo">
          <img src="./assets/img/logo_b.png">
        </div>
        <div class="company">
          <p>{{ companyName }}</p>
          <ul>
            <li>
              <label>전화</label>
              <span>{{ companyPhone }}</span>
            </li>
            <li>
              <label>주소</label>
              <span>{{ companyAddress }}</span>
            </li>
          </ul>
        </div>
        <div class="copyright">
          COPYRIGHT (C) PAYOT ALL RIGHTS RESERVED
        </div>
      </div>
    </div>
   
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: "App",

  data: () => ({
    navActive: 0,
    nowInterval: null,
  }),
  mounted() {
    this.nowInterval = setInterval(() => {
      this.setNow();
      console.log(this.$store.state.now);
    }, 60000);
  },
  destroyed() {
    clearInterval(this.nowInterval);
  },
  computed: {
    ...mapState({
      companyName: state => state.company?.name,
      companyPhone: state => state.company?.phone,
      companyAddress: state => state.company?.address,
    }),
    ...mapGetters([
      'groupByMachineStates',
    ]),
    machineTabs() {
      return Object.keys(this.groupByMachineStates);
    }
  },
  watch: {
    machineTabs(newValue, oldValue) {
      if(newValue.join('') === oldValue.join('')) {
        this.setSelectedCategory(newValue[this.navActive]);
      } else {
        this.setSelectedCategory(newValue[0]);
        this.navActive = 0;
      }
    },
  },
  methods: {
    ...mapMutations({
      setSelectedCategory: 'SET_SELECTED_CATEGORY',
      setNow: 'SET_NOW',
    }),
    setCategory(tabIndex) {
      this.navActive = tabIndex;
      this.setSelectedCategory(this.machineTabs[tabIndex]);
    },
  }
};
</script>

<style lang="scss" scoped>

.header{
  display:flex;
  align-items: center;
  justify-content: space-between;
  height:60px;
  border-bottom:1px solid #e2e2e2;
  padding:0 15px;
  background:#fff;

  img{
    height:36px;
  }
}

.nav{
  position:sticky;
  top:0px;
  z-index:3;
  background:#fff;

  .inner{
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .v-btn{
    flex:1;
    border-radius:0px;
    min-height:50px;
    max-width:150px;
    transition: all 0.5s;
  }
  .v-btn.active{
    color:#1f96d1;
  }
  .v-btn:after{
    position: absolute;
    bottom:0px;
    content:'';
    height:1px;
    width:100%;
    background:#e2e2e2;
  }
  .v-btn.active:after{
    position: absolute;
    bottom:0px;
    content:'';
    height:3px;
    width:100%;
    background:#1f96d1;
  }
}

.footer{
  padding:30px 0;
  background:#fff;
  border-top:1px solid #e2e2e2;
  text-align:center;
  font-size:12px;color:#888;

  .inner{
    max-width:1200px;
    padding:0 20px;
    margin:0 auto;
  }

  .logo{
    margin-bottom:15px;
    img{height:30px; filter:grayscale(0.9);}
  }
  
  .company{
    max-width:600px;
    margin:0px auto;

    p{
      color:#292929;
      text-align:center;
      margin-bottom:8px;
    }
    ul{      
      padding:0px;
      
      li{
        display:inline;
        margin-right:10px;
        margin-bottom:3px;
        font-size:11px;

        label{
          color:#292929;
          margin-right:4px;
        }
      }
      li:last-child{
        margin-right:0px;
      }
    }
  }

  .copyright{
    font-size:11px;
    margin-top:10px;
  }
}

@media screen and(max-width:1200px) {
  .nav{
    .inner{
      .v-btn{
        max-width:100%;
        transition: all 1s;
      }
    }
  }
}

</style>