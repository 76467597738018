<template>
  <v-col cols="12" sm="6" md="4">
    <div class="item">
      <div class="item-head">
        <span :class="{ stop: isWaiting, play: !isWaiting }">{{ isWaiting ? '사용가능' : '동작중' }}</span>
        <strong>{{ machine.name }}</strong>
      </div>
      <div class="item-body">
        <div class="left">
          <span class="weight">{{ machine.size }}</span>
          <strong>
            <img :src="imgName" />
          </strong>
        </div>
        <div class="right">
          <label v-if="isWaiting">사용가능</label>
          <span v-if="!isWaiting">남은시간</span>
          <strong v-if="!isWaiting">{{ leftTime }}</strong>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
export default {
  name: 'MachineState',
  props: {
    machine: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      now: state => state.now,
    }),
    isWaiting() {
      return this.leftTime === null;
    },
    leftTime() {
      if(this.machine.stopAt === null) return null;

      const gap = moment(this.machine.stopAt).diff(moment(this.now), 'minutes');
      if(gap < 0) return null;
      else return gap;
    },
    category() {
      if(this.machine.category === '세탁기') return '세탁기';
      else if(this.machine.category === '건조기') return '건조기';

      if(this.machine.name.includes('세탁기')) return '세탁기';
      else return '건조기';
    },
    imgName() {
      if(this.isWaiting) return require('@/assets/img/stop.png');

      if(this.category === '세탁기') return require('@/assets/img/play.gif');
      else return require('@/assets/img/play-hot.gif');
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border: 1px solid #e2e2e2;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
  .item-head {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    border-bottom: 1px solid #e2e2e2;
    span {
      font-size: 11px;
      line-height: 11px;
      background: #292929;
      color: #fff;
      border-radius: 3px;
      padding: 5px;
      margin-right: 10px;
    }
    strong {
      font-weight: normal;
    }

    span.stop {
      background: #eb0973;
    }
    span.play {
      background: #1f96d1;
    }
  }
  .item-body {
    display: flex;
    min-height: 120px;
    padding: 15px;

    .left {
      display: flex;
      width: 100px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-right: 1px solid #e2e2e2;
      padding-right: 20px;

      span {
        display: block;
        color: #079f6c;
      }
      strong {
        display: block;
        img {
          height: 70px;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding-left: 20px;

      span {
        color: #888;
        font-size: 12px;
        margin-right: 10px;
      }
      strong {
        font-size: 42px;
        line-height: 42px;
      }
      strong:after {
        content: "분";
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        margin-left: 10px;
      }

      label {
        font-size: 28px;
        font-weight: 600;
      }
    }
  }
}
</style>