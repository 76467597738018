<template>
  <div class="errorMsg">
    <v-icon>mdi-alert-circle</v-icon>
    등록되지 않은 매장입니다.
  </div>
</template>

<script>
export default {
  name: 'Error',
}
</script>

<style lang="scss" scoped>
.errorMsg {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>