<template>
  <v-dialog v-model="visible">
    <div class="cont">
      <span>헛걸음방지 시스템</span>
      <p>실제 작동상황과 차이가 있을 수 있으니 <b>직접 확인</b>을 부탁드립니다</p>
      <div class="btns">
        <button @click="() => visible = false">확인</button>
      </div>
    </div>
    
  </v-dialog>
</template>

<script>
export default {
  data: () => {
    return {
      visible: true,
    }
  },
  
}
</script>

<style lang="scss" scoped>
.cont{
  padding:20px;
  background:#fff;

  span{
    display:block;
    font-size:12px;
    color:#898989;
    margin-bottom:10px;
  }
  b{color:#d22828;font-weight:normal}
  p{
    font-size:18px;
  }

  .btns{
    display:flex;
    justify-content: center;
    margin-top:30px;

    button{
      height:40px;
      padding:0 30px;
      border-radius:6px;
      background:#000;
      color:#fff;
    }
  }
  
}

</style>