import Vue from "vue";
import Vuex from "vuex";
import { groupBy } from 'lodash';
// import moment from "moment";
import moment from 'moment-timezone';
import { stateAPI } from "../plugins/request";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    requestTime: "",
    company: {},
    datas: [],
    selectedCategory: "",
    now: moment().tz("Asia/Seoul").format('YYYY-MM-DD HH:mm:ss'),
  },
  getters: {
    groupByMachineStates: state => {
      return groupBy(state.datas, 'category');
    },
  },
  mutations: {
    SET_REQUEST_TIME(state, value) {
      state.requestTime = value;
    },
    SET_COMPANY(state, value) {
      state.company = value;
    },
    SET_DATAS(state, value) {
      state.datas = value;
    },
    SET_SELECTED_CATEGORY(state, value) {
      state.selectedCategory = value;
    },
    SET_NOW(state) {
      state.now = moment().tz("Asia/Seoul").format('YYYY-MM-DD HH:mm:ss');
    },
  },
  actions: {
    async getMachinesState({ commit }, companyId) {
      const { data } = await stateAPI({
        method: 'GET',
        url: `/machines/state/${companyId}`,
      });
      if(!data.company) router.replace({ name: "Error" });
      commit('SET_REQUEST_TIME', data.requestTime);
      commit('SET_COMPANY', data.company);
      commit('SET_DATAS', data.datas);
    },
  },
  modules: {},
});